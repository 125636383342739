import React from "react"
import { CodeBlockDarkTheme } from "../utils"
import { CodeBlock } from "@atlaskit/code"
import ApiResponseCodeBlock from "../components/ApiResponseCode/ApiResponseCodeBlock"
const styles = require("./Pages.module.scss");

export default function Errors() {
  return (
    <React.Fragment>
      <div className={styles.Content}>
        <h1>Errors</h1>
        <p>
          Prestavi uses standard HTTP response codes to indicate the success or failure of an API request. All error responses are formatted using the same structure, as displayed below.
        </p>

        <div className={styles.ErrorType}>
          <div className={styles.Status}>
            <span style={{backgroundColor: "#b9e5d6", color: "#24624d"}}>200</span>
            <p>- OK</p>
          </div>
          <p>This is a success response and everything worked as expected.</p>
        </div>

        <div className={styles.ErrorType}>
          <div className={styles.Status}>
            <span style={{backgroundColor: "#faf0d7", color: "#bf6d0a"}}>401</span>
            <p>- Unauthorized</p>
          </div>
          <p>We couldn’t find a valid API key in the request.</p>
        </div>

        <div className={styles.ErrorType}>
          <div className={styles.Status}>
            <span style={{backgroundColor: "#faf0d7", color: "#bf6d0a"}}>404</span>
            <p>- Not Found</p>
          </div>
          <p>We couldn’t locate the resource that you requested.</p>
        </div>

        <div className={styles.ErrorType}>
          <div className={styles.Status}>
            <span style={{backgroundColor: "#faf0d7", color: "#bf6d0a"}}>418</span>
            <p>- Missing Base URL Region</p>
          </div>
          <p>You didn't use the base URL associated with your account.</p>
        </div>

        <div className={styles.ErrorType}>
          <div className={styles.Status}>
            <span style={{backgroundColor: "#faf0d7", color: "#bf6d0a"}}>422</span>
            <p>- Validation Error</p>
          </div>
          <p>We couldn’t accept the request because it was missing a parameter or a parameter didn’t conform to the expected data structure.</p>
        </div>

        <div className={styles.ErrorType}>
          <div className={styles.Status}>
            <span style={{backgroundColor: "#faf0d7", color: "#bf6d0a"}}>429</span>
            <p>- Too Many Requests</p>
          </div>
          <p>You’ve sent too many requests to the API too quickly.</p>
        </div>

        <div className={styles.ErrorType}>
          <div className={styles.Status}>
            <span style={{backgroundColor: "#f7dede", color: "#ac2323"}}>500</span>
            <p>- Server Error</p>
          </div>
          <p>Something has gone wrong on our end, this is not normal.</p>
        </div>

        <section style={{marginTop:"32px"}}>
          <h2 style={{fontSize: "20px", fontWeight: 500, marginBottom: "-8px"}}>Error Response Structure</h2>
          <ApiResponseCodeBlock response={{
            "status": "error",
            "code": 401,
            "errors": [
              {
                "type": "authentication",
                "message": "Failed to authenticate."
              }
            ]
          }} />
        </section>
      </div>
    </React.Fragment>

  )
}